.custom-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  
  .custom-checkbox-checked-icon {
    color: white;
    font-size: 18px;
    width: 20px !important;
    height: 20px !important;
    background-color: rgba(0, 142, 122, 1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
