.banter-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  
  .loader-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden; /* Ensure the background stays within the bounds */
    background: #008E7A; /* Gradient background */
    animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .banter-loader img {
    z-index: 1; /* Ensure the image is above the rotating background */
    width: auto;
    max-width: 70px;
  }
  